@import '_variables.scss';

.light-theme {
    --primary-color: #FBCB7B;
    --primary-colorl: rgb(172, 213, 221);
    --primary-colorbg: rgb(6, 52, 45);
    --global-bg: #ebebeb;
    --containerBGl: rgb(221,233,233);
    --containerBGd: rgb(62,132,145);
    --secondary-color: rgb(64, 79, 63);
    --secondary-colorl: rgb(109, 122, 118);
    --error-color: $error-color-light;
    --success-color: $success-color-light;
    --warning-color: $warning-color-light;
    --info-color: $info-color-light;
    --white: $white-light;
    --black: $black-light;
    --backgroud-blackLight:#1E1E1E;
    --backgroud-blackDark:#191919;
    --shadow-medium: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    --transition-normal:all 0.3s ease;
    --animation-normal:all 0.5s ease;

    &::selection {
      background: var(--primary-color);
      color: var(--primary-colorl);
    }
  
  &::-moz-selection {
      background: var(--primary-color);
      color: var(--primary-colorl);
    }
  }
  
  .dark-theme {
    --primary-color: $primary-color1-dark;
    --primary-colorbg: $primary-color2-dark;
    --secondary-color: $secondary-color-dark;
    --error-color: $error-color-dark;
    --success-color: $success-color-dark;
    --warning-color: $warning-color-dark;
    --info-color: $info-color-dark;
    --white: $white-dark;
    --black: $black-dark;

    &::selection {
        background: var(--primary-color);
        color: var(--primary-colorl);
      }
    
    &::-moz-selection {
        background: var(--primary-color);
        color: var(--primary-colorl);
      }
  }

  .newline{
    display: block;
  }

  .bold{
    font-weight: 700;
  }

  .mb-10{
    margin-bottom: 10px;
  }

  .mb-20{
    margin-bottom: 20px;
  }

  .pb-20{
    padding-bottom: 20px;
  }


  .mt-10{
    margin-top: 10px;
  }

  .mt-20{
    margin-top: 20px;
  }

// .container {
//     max-width: map-get($container-max-widths, 'sm');
  
//     @media (min-width: map-get($container-max-widths, 'sm')) {
//       max-width: map-get($container-max-widths, 'md');
//     }
  
//     @media (min-width: map-get($container-max-widths, 'md')) {
//       max-width: map-get($container-max-widths, 'lg');
//     }
  
//     @media (min-width: map-get($container-max-widths, 'lg')) {
//       max-width: map-get($container-max-widths, 'xl');
//     }
  
//     @media (min-width: map-get($container-max-widths, 'xl')) {
//       max-width: map-get($container-max-widths, 'xl');
//     }
  
//     // Set padding and auto margins for centering
//     padding-left: $spacing-unit;
//     padding-right: $spacing-unit;
//     margin-left: auto;
//     margin-right: auto;
//   }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
